import React from "react";
import ImageAssets from "../../assets/images";

export default function NavBar() {
  return (
    <nav className="flex justify-between items-center">
      <div className="flex justify-between items-center cursor-pointer">
        <a href="/" className="flex items-center no-default-link">
          <img src={ImageAssets.logo} alt="" height="50" width="50" />
          <span className="text-c-primary text-3xl font-bold ml-1">
            codekickbot
          </span>
        </a>
      </div>

      <div className="flex justify-between items-center w-2/6">
        <span className="text-2xl cursor-pointer">
          <a href="/" className="no-default-link">
            Home
          </a>
        </span>

        <span className="text-2xl cursor-pointer">
          <a href="/#product" className="no-default-link">
            Product
          </a>
        </span>

        <span className="text-2xl cursor-pointer">
          <a href="/pricing" className="no-default-link">
            Pricing
          </a>
        </span>

        <span className="text-2xl cursor-pointer">
          <a href="/faq" className="no-default-link">
            FAQ&apos;s
          </a>
        </span>
      </div>
    </nav>
  );
}
