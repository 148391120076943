import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// containers
import LandingContainer from "../landing";
import PricingContainer from "../pricing";
import FAQContainer from "../faq";
import TermsOfServiceContainer from "../terms-of-service";
import PrivacyPolicyContainer from "../privacy-policy";
import SlackInstallationContainer from "../slack-installation";
import GithubInstallationContainer from "../github-installation";

const RoutesContainer = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LandingContainer />} />
        <Route exact path="/pricing" element={<PricingContainer />} />
        <Route exact path="/faq" element={<FAQContainer />} />
        <Route exact path="/tos" element={<TermsOfServiceContainer />} />
        <Route exact path="/privacy" element={<PrivacyPolicyContainer />} />
        <Route
          exact
          path="/slack-install"
          element={<SlackInstallationContainer />}
        />
        <Route
          exact
          path="/github-install"
          element={<GithubInstallationContainer />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesContainer;
