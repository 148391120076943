import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { githubInstall } from "../../api";

const GithubInstallationContainer = () => {
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const authId = searchParams.get("state");

    const installationId = searchParams.get("installation_id");

    if (authId && authId !== "undefined" && installationId) {
      setIsLoading(true);

      setTimeout(
        async () => await githubInstall({ authId, installationId }),
        1000
      );

      setIsLoading(false);
    }
  }, []);

  return (
    <div className="flex flex-col items-center min-h-screen">
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <div className="flex flex-col items-center mt-20">
          <div className="text-2xl font-semibold">
            CodeKickBot has been installed succesfully!
          </div>
          <div className="text-xl font-semibold mt-4">
            We have DM&apos;ed you the onboarding instructions
          </div>
        </div>
      )}
    </div>
  );
};

export default GithubInstallationContainer;
