import React from "react";
import ImageAssets from "../../assets/images";
import AddToSlackButton from "../../components/AddToSlackButton";

const LandingContainer = () => {
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col items-start w-2/5">
          <span className="text-4xl font-bold">
            Tired of long running pull requests?
          </span>
          <span className="text-xl text-c-gray mt-4">
            Review and merge pull requests faster with Slack reminders and
            notifications.
          </span>

          <div className="mt-10">
            <AddToSlackButton />
          </div>
        </div>

        <div className="w-3/5">
          <img
            src={ImageAssets.Illustration_2}
            alt=""
            width="100%"
            height="100%"
          />
        </div>
      </div>

      <div className="flex justify-between items-center mt-40">
        <div className="flex flex-col w-2/4">
          <span className="text-3xl font-semibold">How it works?</span>
          <span className="text-xl text-c-gray mt-4">
            CodeKickBot can be installed on Slack and GitHub, so that you
            receive actionable insights right into your workspace!
          </span>
        </div>

        <div className="flex justify-between items-center w-2/5">
          <div className="landing-integration-icon-container">
            <img src={ImageAssets.slack_icon} alt="" />
          </div>

          <div className="landing-integration-icon-container">
            <img src={ImageAssets.github_icon} alt="" />
          </div>
        </div>
      </div>

      <div id="product" className="flex justify-center mt-40">
        <span className="text-4xl font-semibold">What CodeKickBot offers?</span>
      </div>

      <div className="flex justify-between items-center mt-20">
        <div className="flex flex-col w-2/5">
          <span className="text-3xl font-semibold">
            Personal reminders and notifications
          </span>
          <span className="text-xl text-c-gray mt-4">
            Receive real-time notifications for review requests, reviews,
            comments on pull requests and more in a DM so that you are not
            bothered of any activity irrelevant to you.
          </span>
        </div>

        <div className="w-3/5">
          <img
            src={ImageAssets.Illustration_1}
            alt=""
            width="100%"
            height="100%"
          />
        </div>
      </div>

      <div className="flex justify-between items-center mt-20">
        <div className="w-3/5">
          <img
            src={ImageAssets.Illustration_3}
            alt=""
            width="100%"
            height="100%"
          />
        </div>

        <div className="flex flex-col w-2/5">
          <span className="text-3xl font-semibold">
            Get all open pull requests
          </span>
          <span className="text-xl text-c-gray mt-4">
            Run the Slack command{" "}
            <span className="slack-code-block slack-code">/codekick pr</span> to
            get a list of open pull requests whenever you want to catch up on
            code reviews.
          </span>
        </div>
      </div>

      {/* <div className="landing-feature-container">
        <div className="landing-feature-text-container">
          <span className="landing-feature-main-text">
            Scheduled pull request reminders
          </span>
          <span className="landing-feature-sub-text">
            Your daily dose of open pull requests delivered to you on autopilot
            so that you know what is waiting on you and your team.
          </span>
        </div>

        <div className="landing-feature-image">
          <img src="" alt="" />
        </div>
      </div> */}

      <div id="testimonials" className="flex justify-center items-center mt-40">
        <div className="flex flex-col w-3/4">
          <span className="text-3xl font-semibold text-center">
            Testimonial
          </span>

          <i className="text-xl text-c-gray mt-10">
            We love using CodeKickBot at The Browser Company! A centrally
            configurable Github notification bot that reminds everyone on the
            team when they&apos;re needed on PRs has solved a lot of code-review
            turnaround issues for us.
          </i>
          <span className="text-xl text-c-gray mt-4">
            - Hursh Agrawal, CTO/Co-Founder at The Browser Company
          </span>
        </div>
      </div>

      <div className="flex justify-center mt-40 mb-40">
        <AddToSlackButton />
      </div>
    </div>
  );
};

export default LandingContainer;
