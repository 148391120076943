import React from "react";
import { CODEKICKBOT_SUPPORT_EMAIL } from "../../utils/constants";

export default function FAQ() {
  return (
    <div>
      <div className="min-h-screen m-5 flex flex-col items-center">
        <div className="w-3/4">
          <h1 className="text-4xl font-semibold text-center mt-10 mb-10">
            FAQ&apos;s
          </h1>

          <div className="mb-5">
            <div className="text-xl text-c-purple">
              1. How to use CodeKickBot?
            </div>

            <p className="mt-2 ml-4 text-base text-c-gray">
              CodeKickBot will notify you within Slack of any relevant activity
              on GitHub, mainly, reviews and comments on pull requests. You can
              run commands within Slack to interact with it.
              <br />
              <br />
              &quot;/codekick connect&quot; - Match GitHub account to their
              corresponding Slack account.
              <br />
              <br />
              &quot;/codekick pr&quot; - Get all open pull requests.
              <br />
              <br />
              &quot;/codekick help&quot; - Seek some help.
              <br />
              <br />
              &quot;/codekick onboard&quot; - Get onboarding instructions.
            </p>
          </div>

          <div className="mb-5">
            <div className="text-xl text-c-purple">
              2. Does CodeKickBot support individual GitHub accounts?
            </div>

            <p className="mt-2 ml-4 text-base text-c-gray">
              NO! CodeKickBot only supports GitHub organization accounts. In
              order to use CodeKickBot, you need to have a{" "}
              <a
                href="https://docs.github.com/en/get-started/learning-about-github/types-of-github-accounts#organization-accounts"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub organization account
              </a>
              .
            </p>
          </div>

          <div className="mb-5">
            <div className="text-xl text-c-purple">
              3. Why am I not receiving any response in a channel from
              CodeKickBot?
            </div>

            <p className="mt-2 ml-4 text-base text-c-gray">
              This is because you haven&apos;t invited CodeKickBot to that
              channel in which you are expecting a response. Use the command
              &quot;/invite @CodeKickBot&quot; in the channel you wish to invite
              CodeKickBot to.
              <br />
              <br />
              Please make sure that CodeKickBot is invited to that specific
              channel, before running a command.
            </p>
          </div>

          <div className="mb-5">
            <div className="text-xl text-c-purple">
              4. Why am I not receiving any notifications on activity in GitHub?
            </div>

            <p className="mt-2 ml-4 text-base text-c-gray">
              In order to be able to receive notifications within Slack on
              relevant activity on GitHub you first need to match your GitHub
              account to your corresponding Slack account. Use the command
              &quot;/codekick connect&quot; and match.
            </p>
          </div>

          <div className="mb-5">
            <div className="text-xl text-c-purple">
              5. How do I remove CodeKickBot from my Slack workspace?
            </div>

            <p className="mt-2 ml-4 text-base text-c-gray">
              Check out the{" "}
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://slack.com/intl/en-pl/help/articles/360003125231-Remove-apps-and-custom-integrations-from-your-workspace#remove-a-custom-integration"
              >
                Slack docs
              </a>{" "}
              for detailed info on how to remove an app from your workspace.
            </p>
          </div>

          <div className="mb-5">
            <div className="text-xl text-c-purple">
              6. What permissions does CodeKickBot have in my Slack workspace?
            </div>

            <p className="mt-2 ml-4 text-base text-c-gray">
              CodeKickBot only reads and saves content that is explicitly and
              directly sent to the app, nothing else. You&apos;ll notice we
              don&apos;t ask for permission to read your Slack messages, we
              don&apos;t need it.
              <br />
              <br />
              The permissions requested for the app have been reviewed and
              approved by Slack and are the absolute minimum permissions
              required for the app to work.
            </p>
          </div>

          <div className="mb-5">
            <p className="text-base mt-10">
              Have a question that isn&apos;t listed? Email us at:{" "}
              {CODEKICKBOT_SUPPORT_EMAIL}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
