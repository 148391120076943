import axios from "axios";

export const githubInstall = async (payload) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/v1/install/github`,
      payload
    );
  } catch (error) {
    return error;
  }
};

export const slackInstall = async (payload) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/v1/install/slack`,
      payload
    );
  } catch (error) {
    return error;
  }
};
