import React from "react";
import { CODEKICKBOT_SUPPORT_EMAIL } from "../../utils/constants";

const Footer = () => {
  return (
    <div className="h-auto" style={{ backgroundColor: "rgb(28, 35, 49)" }}>
      <div className="flex justify-between items-start ml-5 text-white">
        <div className="flex flex-col text-left ml-2 w-1/2">
          <span className="mt-4 text-base">
            Built with hope and lot&apos;s of ❤️
          </span>
        </div>

        <div className="flex flex-col text-left mt-4 w-1/2">
          <span className="text-xl font-medium">COMPANY</span>
          <span className="mt-2">
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li>
                <a href="/privacy" className="no-default-link">
                  Privacy Policy
                </a>
              </li>

              <li>
                <a href="/tos" className="no-default-link">
                  Terms of Service
                </a>
              </li>
            </ul>
          </span>
        </div>

        <div className="flex flex-col text-left mt-4 w-1/2">
          <span className="text-xl font-medium">CONTACT US</span>
          <span className="mt-2">
            <a
              href={`mailto:${CODEKICKBOT_SUPPORT_EMAIL}?subject=Hello`}
              className="no-default-link"
            >
              {CODEKICKBOT_SUPPORT_EMAIL}
            </a>
          </span>
        </div>
      </div>

      <div className="flex flex-col items-center text-center text-base mt-4 text-c-gray">
        <span className="mt-1 mb-1">
          Copyright © {new Date().getFullYear()} CodeKickBot. All rights
          reserved.
        </span>
      </div>
    </div>
  );
};

export default Footer;
