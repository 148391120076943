import React from "react";
import RoutesContainer from "./containers/routes";

// components
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

const App = () => (
  <div>
    <div className="m-10">
      <NavBar />
      <RoutesContainer />
    </div>
    <Footer />
  </div>
);

export default App;
