import Illustration_1 from "./Illustration_1.svg";
import Illustration_2 from "./Illustration_2.svg";
import Illustration_3 from "./Illustration_3.svg";
import logo from "./logo.png";
import add_to_github_white from "./add_to_github_white.png";
import add_to_slack_purple from "./add_to_slack_purple.png";
import add_to_slack_white from "./add_to_slack_white.png";
import company from "./company.png";
import contact from "./contact.png";
import footer_icon from "./footer_icon.svg";
import footer_image from "./footer_image.png";
import github_icon from "./github_icon.svg";
import heart from "./heart.svg";
import india from "./india.svg";
import lead_time from "./lead-time.png";
import primary_illustration from "./primary_illustration.png";
import secondary_illustration from "./secondary_illustration.png";
import slack_icon from "./slack_icon.svg";
import start_your_trial from "./start_your_trial.svg";
import workspace from "./workspace.png";
import AppHome from "./AppHome.png";
import AppDashboard from "./AppDashboard.png";
import AggregatedResults from "./AggregatedResults.png";
import MeasureYourPullRequests from "./MeasureYourPullRequests.png";
import TopContributors from "./TopContributors.png";
import FAQBullet from "./faq_question_bullet.svg";
import Slack_Logo from "./slack_logo.svg";
import LinkedIn_Icon from "./linkedin_icon.svg";

const ImageAssets = {
  Illustration_1,
  Illustration_2,
  Illustration_3,
  logo,
  add_to_github_white,
  add_to_slack_purple,
  add_to_slack_white,
  company,
  contact,
  footer_icon,
  footer_image,
  github_icon,
  heart,
  india,
  lead_time,
  primary_illustration,
  secondary_illustration,
  slack_icon,
  start_your_trial,
  workspace,
  AppHome,
  AppDashboard,
  AggregatedResults,
  MeasureYourPullRequests,
  TopContributors,
  FAQBullet,
  Slack_Logo,
  LinkedIn_Icon,
};

export default ImageAssets;
